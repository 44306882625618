
import Lightbox from '@rat.md/bs-lightbox';
import ready from "@nf/support/ready";

/**
 * Export Lightbox Plugin
 * @returns 
 */
export default async function() {
    await ready();

    // Initialize Lightboxes
    Lightbox.invoke(null, {
        lightbox: {
            loader: true,
            replacePictures: true
        }
    });
}
