const windDir = Object.freeze({
    dirNamesShort: [
      "N",
      "NNO",
      "NO",
      "ONO",
      "O",
      "OSO",
      "SO",
      "SSO",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW",
    ],
    dirNamesLong: [
      "Nord",
      "Nordnordost",
      "Nordost",
      "Ostnordost",
      "Ost",
      "Ostsüdost",
      "Südost",
      "Südsüdost",
      "Süd",
      "Südsüdwest",
      "Südwest",
      "Westsüdwest",
      "West",
      "Westnordwest",
      "Nordwest",
      "Nordnordwest",
    ],
    dirDegrees: [
      11.25,
      33.75,
      56.25,
      78.75,
      101.25,
      123.75,
      146.25,
      168.75,
      191.15,
      213.75,
      236.25,
      258.75,
      281.25,
      303.75,
      326.25,
      348.75,
    ],
  });

export default windDir;
