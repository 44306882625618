import { Chart } from "chart.js/auto";
import { getHistoricalData, getInitialData, kphToKn } from "./wind-infos";

export default async function WindCharts() {
  const graphSelectButtons = document.querySelectorAll<HTMLButtonElement>(".winddata__select");
  const timeDisplay = document.querySelector<HTMLSpanElement>(".winddata__time");
  const today = new Date();
  const canvas = document.querySelector<HTMLCanvasElement>("#graph");
  let currentChart: Chart | null = null;
  let currentChartType: "2d" | "3h" | "1h" = "2d";
  let chartData = { time: [], speedAvg: [], speedHigh: []};
  let initialData: any;
  let historicalData: any;

  // not on surf-page
  if(!canvas) return;

  initialData = await getInitialData();
  historicalData = await getHistoricalData();

  graphSelectButtons.forEach(button => {
    button.addEventListener("click", (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      setActiveGraph(target)
    });
  })
  
  function setActiveGraph(target: any) {
    if (target.classList.contains("active")) return;
    graphSelectButtons.forEach(button => button.classList.remove("active"));
    target.classList.add("active");
    const timespan = parseInt(target.dataset.timespan)
    currentChartType = target.dataset.charttype
    const clonedInitialData = structuredClone(initialData["observations"]);
    if(target.dataset.timeformat === "hours") transformData(historicalData["observations"], timespan);
    else if(target.dataset.timeformat === "minutes") transformData(clonedInitialData.reverse(), timespan);
    renderChart();
  }
  
  const transformData = (data: any, length: number) => {
    chartData.time.length = 0;
    chartData.speedAvg.length = 0;
    chartData.speedHigh.length = 0;
    data.forEach((item: any, index: number) => {
      if(index >= length) return;
      
      const dateObj = new Date(item.obsTimeLocal);
      const hours = String(dateObj.getHours()).padStart(2, "0");
      const minutes = String(dateObj.getMinutes()).padStart(2, "0");
      chartData.time.push(`${hours}:${minutes}`);
      chartData.speedAvg.push(kphToKn(item.metric.windspeedAvg));
      chartData.speedHigh.push(kphToKn(item.metric.windspeedHigh));

      const formattedDay = new Intl.DateTimeFormat("de-DE", {weekday: "long"}).format(dateObj)
      if(index === 0 && dateObj.getDate() == today.getDate()) {
        timeDisplay.innerText = `heute, ${hours}:${minutes}`;
      }
      else if(index === length-1) timeDisplay.innerText = `${formattedDay}, ${hours}:${minutes}`;
    });
  }
  
  const renderChart = async () => {
    try {
      if (!chartData) return;
      if (currentChart) currentChart.destroy();

      Chart.defaults.color = "#FFFFFF";
      Chart.defaults.borderColor = "#000000";
      Chart.defaults.plugins.decimation,
      Chart.defaults.plugins.legend.display = false;
      Chart.defaults.scale.grid.display = false;

      // @ts-ignore: fix: type error
      currentChart = new Chart(canvas, {
        type: "line",
        data: {
          labels: chartData.time.reverse(),
          datasets: [
            {
              label: "Durchschnitt",
              data: chartData.speedAvg.reverse(),
              backgroundColor: "rgba(100, 100, 100, 1)",
              borderColor: "rgb(255, 255, 255)",
              borderWidth: 2,
              pointRadius: window.innerWidth < 554 ? 0 : 4,
              tension: 0.35,
              fill: {
                target: 'origin',
              }
            },
            {
              label: "Maximum",
              data: chartData.speedHigh.reverse(),
              backgroundColor: "rgba(35, 35, 35, 1)",
              borderColor: "rgb(255, 255, 255)",
              borderWidth: 2,
              pointRadius: window.innerWidth < 554 ? 0 : 4,
              tension: 0.35,
              fill: {
                target: 'origin',
              },
            }
          ]
        },
        options: {},
      });
    } catch (error) {
      console.error("An error occurred while rendering the chart:", error);
    }
  }

  transformData(historicalData["observations"].reverse(), 48);
  renderChart();
}
