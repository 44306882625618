import windDir from "./wind-dir";
import { getCurrentData, kphToKn } from "./wind-infos";

const windSpeedDomElement = document.querySelectorAll<HTMLSpanElement>(".wind-speed");
const windDirDomElement = document.querySelectorAll<HTMLSpanElement>(".wind-direction-shortname");
const windGustDomElement = document.querySelector<HTMLSpanElement>(".wind-gust");
const arrowDomElement = document.querySelector<SVGPathElement >("#arrow");

export function shortNameByDegrees(deg: number) {
  for (let i = 0; i < windDir.dirDegrees.length; i++) {
    if (deg <= windDir.dirDegrees[i]) {
      return windDir.dirNamesShort[i];
    }
  }
  return windDir.dirNamesShort[0];
}

export default function WindCompass() {
  const updateWindData = async () => {
    
    // not on surf-page
    if (windSpeedDomElement.length === 0) return;
    
    const currentData = await getCurrentData();
    const obs = currentData.observations[0];

    windSpeedDomElement.forEach(item => item.innerText = kphToKn(obs.metric.windSpeed));
    windDirDomElement.forEach(item => item.innerText = shortNameByDegrees(obs.winddir));
    windGustDomElement.innerText = obs.metric.windGust.toString();
    arrowDomElement.style.transform = `rotate(${obs.winddir}deg)`;
  };

  updateWindData();
  setInterval(updateWindData, 300000);
 }
