export default function History() {
    const historyItems = document.querySelectorAll<HTMLDivElement>(".history__item");

    if(!historyItems) return;

    function AnimateHistory(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                (entry.target as HTMLDivElement).style.opacity = "1";
                (entry.target as HTMLDivElement).querySelector<HTMLDivElement>(".history__item_card").style.transform = "translateX(0)";
                observer.unobserve(entry.target); 
            }
        });
    }

    let options = {
        root: null,
        rootMargin: window.innerWidth < 500 ? "0px" : "-100px",
        threshold: 0.1,
    };

    let observer = new IntersectionObserver(AnimateHistory, options);

    historyItems.forEach(item => {
        observer.observe(item);
    });
}
