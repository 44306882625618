import WindCompass from "./wind-compass";
import WindCharts from "./wind-charts";

// import { historical_dummydata, initial_dummydata } from "../dummydata";

// https://docs.google.com/document/d/14OK6NG5GRwezb6-5C1vQJoRdStrGnXUiXBDCmQP9T9s/edit
const api_key = 'OTA4MThiNWVkNzNhNDJmODgxOGI1ZWQ3M2FiMmY4NGI=';
const stadion_id = 'IHALTE59';

export const getHistoricalData = async () => {
  const url = `https://api.weather.com/v2/pws/observations/hourly/7day?stationId=${stadion_id}&format=json&units=m&apiKey=${atob(api_key)}`;
  const response = await fetch(url);
  return response.json();
  // return historical_dummydata;
}
  
export const getInitialData = async () => {
  const url = `https://api.weather.com/v2/pws/observations/all/1day?stationId=${stadion_id}&format=json&units=m&apiKey=${atob(api_key)}`;
  const response = await fetch(url);
  return response.json();
  // return initial_dummydata;
}

export const getCurrentData = async () => {
  const url = `https://api.weather.com/v2/pws/observations/current?stationId=${stadion_id}&format=json&units=m&apiKey=${atob(api_key)}`;
  const response = await fetch(url);
  return response.json();
}

/** Converts kilometers per hour to knots. */
export const kphToKn = (kph: number) => {
  const value = kph / 1.852 ;
  return value.toFixed(2);
}

export default async function WindInfos() {
  WindCompass();
  WindCharts();
};
