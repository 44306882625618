
import '../scss/main/theme.scss';

import { invokeKeenSlider } from "@nf/keen-slider-bridge";

import invokeBootstrap from "./plugins/bootstrap";
import invokeLightbox from "./plugins/lightbox";
import invokeNavbar from "./plugins/navbar";
import invokeScrollTop from "./plugins/scroll-top";
import invokeWindInfos from "./plugins/wind-infos/wind-infos";
import invokeKlubSlider from "./plugins/klubslider";
import invokeHistory from "./plugins/history";
import invokeImageGallery from "./plugins/imagegallery";
import invokeFade from "./plugins/fade";
import invokeFooter from "./plugins/footer";
import invokeWebcamViewer from "./plugins/webcam-viewer.ts";

// Invoke NFCore Plugins
invokeBootstrap();
invokeKeenSlider();
invokeLightbox();
invokeNavbar();
invokeScrollTop();
invokeWindInfos(); 
invokeKlubSlider();
invokeHistory();
invokeImageGallery();
invokeFade();
invokeFooter();
invokeWebcamViewer();
