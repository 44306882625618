export default function () {    
    const navObs = document.querySelectorAll('.footer-section-meta');
    const secondaryNav = document.querySelector('.secondarynavigation');    


    const navOptions = {
        rootMargin: "-20% 0% 0% 0%"
    };
    if(!secondaryNav){
        return;
    }    
    const navObserver = new IntersectionObserver(function (entries, navObserver) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                secondaryNav.classList.remove('fade-in');
            } else {
                secondaryNav.classList.add('fade-in');
            }
        });
    }, navOptions);

    for (const navOb of navObs) {
        navObserver.observe(navOb);
    }
}
