
import { Popover,Tooltip } from "bootstrap";
import query from "@nf/support/query";
import ready from "@nf/support/ready";

/**
 * Export Bootstrap Plugin
 * @returns 
 */
export default async function () {
    await ready();

    // Initialize Popovers
    query('[data-bs-toggle="popover"][data-toggle="popover"]', (el) => {
        return Popover.getOrCreateInstance(el);
    });

    // Initialize Tooltips
    query('[data-bs-toggle="tooltip"][data-toggle="tooltip"]', (el) => {
        return Tooltip.getOrCreateInstance(el);
    });
};
