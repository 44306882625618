import ready from "@nf/support/ready";
import KeenSlider from 'keen-slider';

export default async function () {
    await ready();

    let items = document.querySelectorAll('#klubslider.carousel .carousel-item')
    

    items.forEach((el) => {
        const minPerSlide = 2
        let next = el.nextElementSibling
        for (var i=1; i<minPerSlide; i++) {
            if (!next) {
                // wrap carousel by using first child
                next = items[0]
              }
            let cloneChild = next.cloneNode(true)
            el.appendChild(cloneChild.children[0])
            next = next.nextElementSibling
        }
    })
}
