import query from "@nf/support/query";
import ready from "@nf/support/ready";

export default async function () {
    await ready();

    const fades = query('.fadein');
    const isMobile = window.innerWidth < 768;
    const options = {
        rootMargin: `-50% 0% ${isMobile ? "-20%" : "-40%"} 0%`
    };
    const fadeObserver = new IntersectionObserver(function (entries, fadeObserver) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.contains('reveal') ?
                entry.target.classList.add('reveal--active') :
                entry.target.classList.add('fadein--active');
                fadeObserver.unobserve(entry.target);
            }
        });
    }, options);
    
    fades.map((fade: HTMLElement) => {
        fade.classList.contains('reveal') ?
        fade.classList.add('reveal--on') :
        fade.classList.add('fadein--on')
        fadeObserver.observe(fade);
        // let i = 0
        // fade.querySelectorAll('img:not([src$=".svg"])').forEach((img: HTMLImageElement) => {
        //     img.style.transitionDelay = i + "ms";
        //     i+=100;
        // })
    });
}
